//Passar para i18n data
export default [
  {
    label: "Botão com Call-to-Action (CTA)",
    value: "callToAction",
  },
  {
    label: "CNPJ",
    value: "cnpj",
  },
  {
    label: "CPF",
    value: "cpf",
  },
  {
    label: "CPF ou CNPJ",
    value: "cpf_cnpj",
  },
  {
    label: "Data",
    value: "date",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Hora",
    value: "hour",
  },
  {
    label: "Lista",
    value: "suggestion",
  },
  {
    label: "Lista com subfluxo",
    value: "subflow",
  },
  {
    label: "Mensagem final",
    value: "end",
  },
  {
    label: "Sem resposta",
    value: "skip",
  },
  {
    label: "Telefone",
    value: "tel",
  },
  {
    label: "Texto",
    value: "text",
  },
  {
    label: "Nome",
    value: "name",
  },
  {
    label: "Número",
    value: "number",
  },
  {
    label: "Encerrar Conversa",
    value: "close_conversation",
  },
];
