<template>
  <div
    class="chatbot-question-form rounded-lg border border-solid mb-6 flex shadow-md"
    :class="
      isAgent || question.data.agentId ? 'border-warning' : 'border-primary'
    "
  >
    <div
      class="cursor-move flex item-center self-stretch border-solid border-primary border-0 border-r p-4"
    >
      <feather-icon
        v-if="!question.data.agentId"
        :icon="icon"
        :svgClasses="isAgent ? 'text-warning' : 'text-primary'"
      />
      <feather-icon v-else :icon="icon" svgClasses="assistant-color" />
    </div>
    <div class="flex flex-wrap w-full" v-if="!isAgent">
      <div class="p-4 w-full">
        <div class="flex flex-wrap mb-3">
          <!-- <input type="text" class="h-12 w-full vs-inputx vs-input--input input-onboarding" data-vv-validate-on="blur"
            v-validate="'required|min:3'" danger-text="1|2|3" name="question" v-model="message" /> -->
          <textarea
            rows="2"
            class="w-full vs-inputx vs-input--input input-onboarding"
            data-vv-validate-on="blur"
            v-validate="'required|min:3'"
            danger-text="1|2|3"
            name="question"
            v-model="message"
            style="resize: none; font-family: sans-serif"
          />
          <span class="text-danger text-sm">{{
            errors.first("question")
          }}</span>
          <div class="label-onboarding">{{ $t("BotMessage") }}</div>
        </div>
        <div class="flex flex-wrap w-full mb-3 flex-col">
          <div class="flex flex-wrap">
            <div class="flex flex-wrap w-1/2">
              <input
                type="text"
                class="h-12 mr-2 w-full vs-inputx vs-input--input input-onboarding"
                data-vv-validate-on="blur"
                v-validate="'required|min:3'"
                danger-text="1|2|3"
                name="label"
                v-model="label"
              />
              <div class="label-onboarding">{{ $t("MessageTag") }}</div>
            </div>
            <div class="flex flex-col w-1/2">
              <div class="relative">
                <input
                  type="text"
                  class="h-12 w-full vs-inputx vs-input--input input-onboarding pl-7 group"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:3'"
                  danger-text="1|2|3"
                  name="shortcut"
                  v-model="shortcut"
                />
                <span
                  class="absolute left-1 top-1/2 transform -translate-y-1/2 text-gray-400"
                >
                  <div class="p-1 text-lg">#</div>
                </span>
              </div>
              <div class="label-onboarding left flex items-center">
                {{ $t("Shortcut") }}

                <vx-tooltip
                  :text="$t('ShortcutTooltip')"
                  color="primary"
                  class="ml-1"
                >
                  <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
                </vx-tooltip>
              </div>
            </div>
          </div>
          <div class="flex flex-wrap w-full">
            <div class="flex flex-wrap w-1/2">
              <!--entity-->
              <input
                type="text"
                class="h-12 mr-2 w-full vs-inputx vs-input--input input-onboarding"
                name="entity"
                v-model="entity"
              />
              <div class="label-onboarding flex item-center">
                {{ $t("Entity") }}
                <vx-tooltip
                  :text="$t('EntityTooltip')"
                  color="primary"
                  class="ml-1"
                >
                  <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
                </vx-tooltip>
              </div>
            </div>
            <div class="flex flex-wrap w-1/2">
              <!--intent-->
              <input
                type="text"
                class="h-12 w-full vs-inputx vs-input--input input-onboarding"
                name="intent"
                v-model="intent"
              />
              <div class="label-onboarding flex item-center">
                {{ $t("Intention") }}
                <vx-tooltip
                  :text="$t('IntentionToolTip')"
                  color="primary"
                  class="ml-1"
                >
                  <feather-icon icon="HelpCircleIcon" svgClasses="w-4 h-4" />
                </vx-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mb-3">
          <v-select
            id="responseType_edit"
            v-model="responseType"
            :clearable="false"
            :options="filteredResponseOptions"
            name="responseType"
            class="w-full"
          />
          <div class="label-onboarding">Selecione o tipo de resposta</div>
          <vs-checkbox
            v-model="forceQuestion"
            class="mt-3"
            v-show="
              responseType &&
              responseType.value &&
              /^(name|cpf|cpf_cnpj|cnpj|email|phone)$/i.test(responseType.value)
            "
            >Forçar sempre fazer essa pergunta</vs-checkbox
          >
        </div>

        <div class="flex flex-wrap mb-3">
          <div v-if="file" class="media-card">
            <div class="p-1">
              <img
                width="50"
                height="50"
                v-if="getMimetype(file).includes('image')"
                :src="tempFile(file)"
                alt="Imagem selecionada"
              />
              <div v-else class="fileIcon">
                <feather-icon icon="FileIcon" />
              </div>
            </div>

            <div class="filename">
              {{ realFileName(file) }}
            </div>

            <div class="actions">
              <vs-button
                type="flat"
                color="dark"
                icon-pack="feather"
                icon="icon-arrow-down-circle"
                class="mt-1"
                :href="tempFile(file)"
              />
              <vs-button
                type="flat"
                color="dark"
                icon-pack="feather"
                icon="icon-trash-2"
                class="mt-1"
                @click="file = null"
              />
            </div>
          </div>
          <a
            v-else
            class="mr-3"
            href="javascript:void(0)"
            @click="triggerAddFile"
          >
            <feather-icon
              icon="PlusIcon"
              class=""
              svgClasses="w-3 h-3 text-primary"
            />
            Adicionar Mídia
          </a>
          <div class="ml-3 flex items-center">
            <a href="javascript:void(0)" @click="openWebhookPopup">
              <feather-icon
                icon="RepeatIcon"
                class=""
                svgClasses="w-3 h-3 text-primary"
              />
              {{ $t("WebhookCall") }}
            </a>
          </div>
          <input
            id="image_file"
            type="file"
            ref="fileUpload"
            class="hidden"
            accept="*"
            @change="addFile"
          />
        </div>
        <div
          class="flex items-center justify-between gap-x-2"
          v-if="responseType.value === 'callToAction'"
        >
          <div class="flex flex-wrap w-1/2">
            <div class="relative w-full">
              <input
                type="text"
                class="h-12 mr-2 w-full vs-inputx vs-input--input input-onboarding"
                style="padding-right: 70px"
                data-vv-validate-on="blur"
                v-validate="'required|min:3|max:20'"
                danger-text="1|2|3"
                name="callToActionButtonText"
                v-model="callToActionButtonText"
                maxlength="20"
              />
              <span
                class="font-semibold absolute"
                style="
                  right: 10px;
                  top: 50%;
                  transform: translateY(-45%);
                  font-size: 0.8rem;
                  color: #777;
                "
              >
                {{ callToActionButtonText.length }}/20
              </span>
            </div>
            <div class="label-onboarding">{{ $t("ButtonText") }}</div>
          </div>
          <div class="flex flex-wrap w-1/2">
            <input
              type="text"
              class="h-12 w-full vs-inputx vs-input--input input-onboarding"
              data-vv-validate-on="blur"
              v-validate="'required|min:3|url'"
              danger-text="1|2|3"
              name="url"
              v-model="targetLink"
            />
            <div class="label-onboarding">{{ $t("TargetLink") }}</div>
            <span class="text-danger text-sm">{{ errors.first("url") }}</span>
          </div>
        </div>
        <div v-show="responseType.value == 'suggestion'">
          <div class="flex relative flex-wrap mb-3">
            <input
              type="text"
              class="h-12 w-full vs-inputx vs-input--input input-onboarding"
              name="suggestions"
              ref="suggestionsInput"
              v-model="suggestionsInput"
              @keyup="keyupInput"
              @blur="blurInput"
              maxlength="20"
            />
            <span class="font-semibold absolute floatCounter">
              {{ suggestionsInput.length }}
            </span>
            <span class="text-danger text-sm">{{
              errors.first("question")
            }}</span>
            <div class="label-onboarding">
              Opção {{ suggestionsArray.length + 1 }} ({{
                suggestionsInput.length
              }}/20)
            </div>
            <span v-if="optionsError" class="text-danger text-sm">{{
              $t("OptionsNotNull")
            }}</span>
          </div>
          <div class="flex flex-wrap mb-3" v-show="suggestionsArray.length > 0">
            <vs-chip
              closable
              color="primary mr-3"
              close-icon="close"
              v-for="(currentsuggestion, index) in suggestionsArray"
              :key="index"
              @click="removeOption(index, 'suggestionsArray')"
              >{{ currentsuggestion }}</vs-chip
            >
          </div>
        </div>
        <div v-show="responseType.value == 'subflow'">
          <div class="flex relative flex-wrap mb-3">
            <input
              type="text"
              class="h-12 w-full vs-inputx vs-input--input input-onboarding"
              name="subflows"
              ref="subflowsInput"
              v-model="subflowsInput"
              @keyup="keyupInput"
              @blur="blurInput"
              maxlength="20"
            />
            <span class="font-semibold absolute floatCounter">
              {{ subflowsInput.length }}
            </span>
            <span class="text-danger text-sm">{{
              errors.first("question")
            }}</span>
            <div class="label-onboarding">
              Opção {{ subflowsArray.length + 1 }} ({{
                subflowsInput.length
              }}/20)
            </div>
          </div>
          <span v-if="optionsError" class="text-danger text-sm">{{
            $t("OptionsNotNull")
          }}</span>
          <div class="flex flex-wrap mb-3" v-show="subflowsArray.length > 0">
            <vs-chip
              closable
              color="primary mr-3"
              close-icon="close"
              v-for="(currentsuggestion, indexChildren) in subflowsArray"
              :key="indexChildren"
              @click="removeOption(indexChildren, 'subflowsArray')"
              >{{ currentsuggestion }}</vs-chip
            >
          </div>
        </div>
        <!-- BOTÕES FINAL -->
        <div class="w-full flex flex-wrap justify-between">
          <vs-button
            class="w-full mt-6 md:mb-8 md:w-2/5"
            color="danger"
            type="border"
            @click="cancelEdition()"
          >
            Cancelar
          </vs-button>
          <vs-button
            class="w-full mt-4 text-white md:w-1/2 mt-6 mb-8"
            color="success"
            @click="saveQuestion()"
          >
            Salvar Pergunta
          </vs-button>
        </div>
      </div>
    </div>
    <div v-if="isAgent" class="w-full p-4">
      <vs-row>
        <vs-col vs-w="12">
          <vs-input
            :label="$t('MessageTitle')"
            v-model="messageTitle"
            name="messageTitle"
            class="w-full mb-4"
          />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="12">
          <label class="vs-input--label mb-2 mr-1">Mensagem</label>
          <vs-textarea rows="2" v-model="agentMessage" />
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="12" class="mt-4">
          <vs-select
            label="Selecione um modelo de agente IA"
            v-model="agent"
            class="w-full"
            autocomplete
          >
            <vs-select-item
              v-for="(item, index) in agents"
              :key="index"
              :value="item"
              :text="item.name"
            />
          </vs-select>
          <div class="flex flex-wrap gap-x-2">
            <a
              class="mr-3 cursor-pointer text-warning"
              @click="openCreateAgent()"
            >
              <feather-icon icon="PlusIcon" svgClasses="w-3 h-3" />
              Criar novo modelo de agente IA
            </a>
            <a
              v-if="Object.keys(agent || {}).length > 0"
              class="mr-3"
              :style="{
                cursor: 'pointer',
                color: 'rgb(255, 159, 67)',
              }"
              @click="editAgent()"
            >
              <feather-icon
                icon="EditIcon"
                class="pr-0.5"
                svgClasses="w-3 h-3 warning"
              />
              Editar modelo selecionado
            </a>
          </div>
        </vs-col>
      </vs-row>
      <div class="w-full flex flex-wrap justify-between">
        <vs-button
          class="w-full mt-6 md:mb-8 md:w-2/5"
          color="danger"
          type="border"
          @click="cancelAgentEdition()"
        >
          Cancelar
        </vs-button>
        <vs-button
          class="w-full mt-4 text-white md:w-1/2 mt-6 mb-8"
          color="success"
          @click="saveAgent()"
        >
          Salvar
        </vs-button>
      </div>
    </div>

    <vs-popup
      :active.sync="AgentPopup"
      :title="isEditing ? 'Editar Agente IA' : 'Adicionar Agente IA'"
      style="z-index: 100000"
      fullscreen
    >
      <create-agent-popup
        v-if="AgentPopup"
        :currentAgent="currentAgent ? currentAgent : {}"
        :isEditing="isEditing"
        @closeAgentPopup="closeAgentPopup"
        :toIndex="question.data.toIndex"
        ref="createAgentPopup"
      />
    </vs-popup>
  </div>
</template>

<script>
import axios from "@/axios";
import mime from "mime/lite";
import vSelect from "vue-select";
import CreateAgentPopup from "../agent/CreateAgentPopup.vue";
import responseOptions from "./responseOptions";

export default {
  name: "chatbot-question",
  components: {
    "v-select": vSelect,
    CreateAgentPopup,
  },
  props: {
    questionForm: {
      type: Object,
      required: true,
    },
    questionIndex: {
      type: Number,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    indexArrayPosition: {
      type: Number,
      required: true,
    },
    isAgent: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      toIndex: null,
      agents: [],
      agent: {},
      agentMessage: "",
      callToActionButtonText: "",
      targetLink: "",
      messageTitle: "",
      AgentPopup: false,
      currentAgent: {},
      isEditing: false,
      file: null,
      whatsappMediaID: null,
      question: "",
      message: "",
      label: "",
      shortcut: "",
      entity: "",
      intent: "",
      children: [],
      suggestions: [],
      suggestionsArray: [],
      suggestionsInput: "",
      subflowsArray: [],
      subflowsInput: "",
      responseType: { label: "", value: "" },
      responseOptions,
      optionsError: false,
      forceQuestion: false,
    };
  },
  computed: {
    filteredResponseOptions: function () {
      let options = responseOptions;
      if (this.indexArrayPosition >= 3)
        options = options.filter((option) => option.value != "subflow");
      return options;
    },
  },
  created: async function () {
    this.question = JSON.parse(JSON.stringify(this.questionForm));

    if (this.question.callToAction) {
      this.callToActionButtonText = this.question.callToAction.buttonText || "";
      this.targetLink = this.question.callToAction.url || "";
    }

    this.currentAgent = {};
    if (this.isAgent) {
      await this.getAgents();

      this.currentAgent =
        this.agents.find((e) => e._id === this.question.data.agentId) || {};
    }

    if (this.question.children) this.children = this.question.children;
    if (this.question.suggestions) this.suggestions = this.question.suggestions;

    this.file = this.question.data.file;
    this.whatsappMediaID = this.question.data.whatsappMediaID;

    this.message = Array.isArray(this.question.data.text)
      ? this.question.data.text[0]
      : this.question.data.text;
    this.label = this.question.label;
    this.shortcut = this.question.shortcut;
    this.entity =
      this.question.entity.length > 0
        ? this.question.entity.startsWith("@")
          ? this.question.entity.slice(1, this.question.entity.length)
          : this.question.entity
        : "";
    this.intent =
      this.question.intent.length > 0
        ? this.question.intent.startsWith("#")
          ? this.question.intent.slice(1, this.question.intent.length)
          : this.question.intent
        : "";
    this.forceQuestion = this.question.forceQuestion;
    this.responseType = this.responseType
      ? responseOptions.find(
          (option) => option.value === this.question.input_type
        )
      : { label: "", value: "" };

    if (this.question.data.agentId) {
      this.agent = this.agents.find(
        (agent) => agent._id === this.question.data.agentId
      );
      this.agentMessage = this.question.data.text;
      this.messageTitle = this.question.label || "";
    }
  },
  mounted: function () {
    if (!this.question.id || this.question.id === "") this.saveQuestion();
    if (this.question.children && this.question.children.length > 0) {
      this.mountOptionsInput("subflowsArray", "subflowsInput", "children");
    } else if (
      this.question.suggestions &&
      this.question.suggestions.length > 0
    ) {
      this.mountOptionsInput(
        "suggestionsArray",
        "suggestionsInput",
        "suggestions"
      );
    }
  },
  watch: {
    responseType: function (responseType) {
      this.suggestionsArray = [];
      this.subflowsArray = [];
      if (responseType && responseType.value === "subflow")
        this.mountOptionsInput("subflowsArray", "subflowsInput", "children");
      if (responseType && responseType.value === "suggestion")
        this.mountOptionsInput(
          "suggestionsArray",
          "suggestionsInput",
          "suggestions"
        );
    },
    subflowsArray: function (array) {
      if (
        this.responseType &&
        this.responseType.value === "subflow" &&
        array.length < 1
      )
        this.optionsError = true;
      else this.optionsError = false;
    },
    suggestionsArray: function (array) {
      if (
        this.responseType &&
        this.responseType.value === "suggestion" &&
        array.length < 1
      )
        this.optionsError = true;
      else this.optionsError = false;
    },
    agent: function (newAgent) {
      this.currentAgent = newAgent;
    },
  },
  methods: {
    saveWebhookCalls(idsArray) {
      this.question.webhookCalls = idsArray;
      this.saveQuestion();
    },
    closeAgentPopup({ agent, toIndex }) {
      this.AgentPopup = false;
      this.isEditing = false;
      this.currentAgent = {};
      this.toIndex = toIndex;

      this.question.data.toIndex = toIndex;

      if (agent) {
        this.agents.push(agent);
        this.agent = agent;
      }
    },
    async openCreateAgent() {
      this.AgentPopup = true;
      this.isEditing = false;
      this.currentAgent = {};
    },
    editAgent() {
      this.AgentPopup = true;
      this.isEditing = true;
      this.currentAgent = this.agent;
    },

    getMimetype(fileUrl) {
      return mime.getType(fileUrl) || "";
    },
    fileName(fileUrl) {
      const fileSplit = fileUrl.split("/");
      return decodeURIComponent(fileSplit[fileSplit.length - 1]);
    },
    realFileName(fileUrl) {
      const fileName = this.fileName(fileUrl);

      const fileNameParts = fileName.split(".");
      const ext = fileNameParts[fileNameParts.length - 1];

      const fileNameSplit = fileName.split("_");

      fileNameSplit.pop();

      return fileNameSplit.join("_") + "." + ext;
    },
    tempFile(fileUrl) {
      return `${process.env.VUE_APP_API_URL}p/chat/media/tempFile/${fileUrl}`;
    },
    triggerAddFile() {
      this.$refs.fileUpload.click();
    },
    async deleteFile(fileName) {
      try {
        await axios.delete(`p/chat/media/upload/${fileName}`);

        this.file = null;
        this.whatsappMediaID = null;
      } catch (e) {
        this.$vs.notify({
          title: "Erro de Imagem",
          text: "Erro ao deletar a imagem",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning",
          position: "top-right",
          time: 4000,
        });
      }
    },
    async addFile(event) {
      const file = event.target.files[0];

      if (file) {
        try {
          this.$vs.loading();

          const formData = new FormData();
          formData.append("content", file);
          formData.append("filename", file.name);
          formData.append(
            "chat",
            this.$store.state.chatbotManagement.current_chatbot.id
          );

          const res = await axios.post("p/chat/media/upload", formData);

          this.file = res.data.data;

          const mediaIdRes = await axios.post("p/chat/make-media-id", formData);

          this.whatsappMediaID = mediaIdRes.data.data;
        } catch (e) {
          this.$vs.notify({
            title: "Erro de Imagem",
            text: "Erro ao fazer upload da imagem",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
            position: "top-right",
            time: 4000,
          });
        }
        this.$vs.loading.close();
      }
    },
    async getAgents() {
      try {
        const response = await this.$http.get("/p/chat/flows/list", {
          params: {
            acc: this.$store.state.acc.current_acc.id,
          },
        });

        this.agents = response.data.data;
      } catch (error) {
        //console.log(error);
      }
    },
    openWebhookPopup() {
      this.$emit(
        "open-webhook-popup",
        this.question.webhookCalls,
        this.question.id
      );
    },
    mountOptionsInput(inputArray, inputField, prop) {
      const self = this;
      const chipEnter = (input) => {
        self[inputArray].push(input);
        self[inputField] = "";
      };

      if (self[prop].length > 0) {
        self[prop].forEach((item) => {
          chipEnter(prop === "suggestions" ? item : item.id);
        });
      }
    },
    keyupInput(e) {
      if (e.keyCode === 13 && e.target.value) {
        this[`${e.target.name}Array`].push(e.target.value);
        this[`${e.target.name}Input`] = "";
      }
    },
    blurInput(e) {
      if (e.target.value) {
        this[`${e.target.name}Array`].push(e.target.value);
        this[`${e.target.name}Input`] = "";
      }
    },
    removeOption(index, array) {
      const self = this;
      const removeSubFlow = async () => {
        self[array].splice(index, 1);
      };
      if (array === "subflowsArray") {
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: "Deletar subfluxo",
          text: "Deseja deletar o subfluxo? Isto irá excluir permanentemente o subfluxo.",
          accept: removeSubFlow,
          acceptText: this.$t("Delete"),
          cancelText: this.$t("Cancel"),
        });
      } else {
        this[array].splice(index, 1);
      }
    },
    async cancelEdition() {
      this.responseType = {};
      this.suggestionsArray = [];
      this.subflowsInput = [];
      this.subflowsArray = [];
      this.suggestionsInput = [];

      this.$emit("cancel-edit");
    },
    async cancelAgentEdition() {
      this.$emit("cancel-agent-edition");
    },
    saveQuestion() {
      let newQuestion = JSON.parse(JSON.stringify(this.question));

      newQuestion.input_type = this.responseType.value;

      if (newQuestion.input_type === "callToAction") {
        newQuestion.callToAction = {
          buttonText: this.callToActionButtonText,
          url: this.targetLink,
        };

        if (
          !this.targetLink.startsWith("http://") &&
          !this.targetLink.startsWith("https://")
        ) {
          this.$vs.notify({
            title: this.$t("UrlError"),
            text: this.$t("UrlStartsWith"),
            color: "danger",
            position: "top-right",
          });
          return;
        }
      }

      if (!newQuestion.id || newQuestion.id === "") {
        let m = new Date();
        m.setMonth(m.getMonth() + Math.floor(Math.random() * 2) + 100);
        newQuestion.id =
          String(Math.floor(Math.random() * 2000)) +
          String(m.getTime() + Math.floor(Math.random() * 2) + 10000);
      }

      if (
        (newQuestion.input_type === "suggestion" &&
          this.suggestionsArray.length < 1) ||
        (newQuestion.input_type === "subflow" && this.subflowsArray.length < 1)
      )
        this.optionsError = true;
      else this.optionsError = false;

      if (!this.optionsError && this.message.length >= 3) {
        if (
          Array.isArray(this.questionForm.data.text) &&
          this.message === this.questionForm.data.text[0]
        )
          newQuestion.data.text = this.questionForm.data.text;
        else newQuestion.data.text = this.message;

        if (this.file === this.questionForm.data.file) {
          newQuestion.data.file = this.questionForm.data.file;
          newQuestion.data.whatsappMediaID =
            this.questionForm.data.whatsappMediaID;
        } else if (this.file) {
          newQuestion.data.file = this.file;
          newQuestion.data.whatsappMediaID = this.whatsappMediaID;
        } else {
          delete newQuestion.data.file;
          delete newQuestion.data.whatsappMediaID;
        }

        newQuestion.label = this.label;
        newQuestion.shortcut = this.shortcut;
        newQuestion.entity =
          this.entity.length > 0
            ? this.entity.startsWith("@")
              ? this.entity
              : `@${this.entity}`
            : "";
        newQuestion.intent =
          this.intent.length > 0
            ? this.intent.startsWith("#")
              ? this.intent
              : `#${this.intent}`
            : "";

        if (this.suggestionsArray.length > 0) {
          newQuestion.suggestions = this.suggestionsArray;
        } else delete newQuestion.suggestions;

        if (this.subflowsArray.length > 0) {
          newQuestion.children = this.subflowsArray.map((childId) => {
            let child = { data: [], id: childId };
            const indexChildAlreadyExists = this.children.findIndex(
              (childItem) => childItem.id === childId
            );
            if (indexChildAlreadyExists != -1)
              child = this.children[indexChildAlreadyExists];

            return { ...child };
          });
          newQuestion.suggestions = this.subflowsArray;
        } else delete newQuestion.children;

        newQuestion.author = "assistant";

        newQuestion.forceQuestion =
          /^(name|cpf|cpf_cnpj|cnpj|email|phone)$/i.test(
            this.responseType.value
          ) && this.forceQuestion;

        let custom = false;
        const verifyQuestionForm = JSON.parse(
          JSON.stringify(this.questionForm)
        );
        const verifyNewQuestion = JSON.parse(JSON.stringify(newQuestion));
        delete verifyQuestionForm.children;
        delete verifyNewQuestion.children;

        const isCustom = (arrayForm, arrayQuestion) => {
          Object.entries(arrayQuestion).forEach((entrie) => {
            if (entrie[0] === "children") return;
            if (Array.isArray(entrie[1])) {
              if (!Array.isArray(arrayForm[entrie[0]])) custom = true;
              else if (entrie[1].length != arrayForm[entrie[0]].length)
                custom = true;
              else
                entrie[1].forEach((itemArray) => {
                  if (
                    arrayForm[entrie[0]] &&
                    Array.isArray(arrayForm[entrie[0]]) &&
                    !arrayForm[entrie[0]].includes(itemArray)
                  )
                    custom = true;
                });
            } else if (typeof entrie[1] === "object" && entrie[1] !== null) {
              if (!arrayForm || !arrayForm[entrie[0]]) {
                custom = true;
              } else {
                isCustom(arrayForm[entrie[0]], entrie[1]);
              }
            } else if (arrayForm[entrie[0]] !== entrie[1]) custom = true;
          });
        };

        isCustom(verifyQuestionForm, verifyNewQuestion);
        this.$emit("save-question", this.questionIndex, newQuestion, custom);
      } else {
        this.error;
      }
    },
    saveAgent() {
      let newQuestion = JSON.parse(JSON.stringify(this.question));

      if (!newQuestion.id || newQuestion.id === "") {
        let m = new Date();
        m.setMonth(m.getMonth() + Math.floor(Math.random() * 2) + 100);
        newQuestion.id =
          String(Math.floor(Math.random() * 2000)) +
          String(m.getTime() + Math.floor(Math.random() * 2) + 10000);
      }

      const backToFlowFunction = this.agent.functions.find(
        (fn) => fn.name === "back_to_flow"
      );

      if (backToFlowFunction.enabled) {
        newQuestion.data.toIndex = this.toIndex;
      }

      newQuestion.data.text = this.agentMessage;
      newQuestion.label = this.messageTitle;
      newQuestion.data.agentId = this.agent._id;
      newQuestion.data.agentName = this.agent.name;
      newQuestion.data.assistantId = this.agent.assistantId;
      newQuestion.agentType = "assistant";
      newQuestion.input_type = "assistant";
      newQuestion.intentions = this.agent.intentions;
      newQuestion.entities = this.agent.entities;
      newQuestion.keyEntities = this.agent.keyEntities;

      this.$emit("save-question", this.questionIndex, newQuestion, true);
    },
  },
};
</script>

<style lang="scss">
.media-card {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  background-color: #9c999979;
  border: 1px solid #6e6e6e27;
  border-radius: 5px;

  .fileIcon {
    background-color: #8a868627;
    border-radius: 5px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filename {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 8pt;
  }

  .actions {
    display: flex;
  }
}

.label-onboarding {
  font-size: 0.85rem;
  background-color: white;
  margin-left: 1rem;
  padding: 0 0.5rem 0 0.5rem;
  width: fit-content;
  position: relative;
  bottom: -10px;
  order: -1;
}

.input-onboarding {
  padding-left: 8px;
  font-size: 1rem;
}

.input-onboarding:focus {
  border: solid 1px rgba(var(--vs-primary), 1);
}

.input-onboarding:focus + span + .label-onboarding,
.input-onboarding:focus span div,
.input-onboarding:focus + .label-onboarding,
.vue-tel-input:focus-within + span + .label-onboarding,
.vue-tel-input:focus-within + span + span + .label-onboarding {
  color: rgba(var(--vs-primary), 1);
}

.label-onboarding.left {
  margin-left: 20px !important;
}

textarea.vs-inputx {
  height: 62px;
  padding-top: 10px;
}

.assistant-color {
  border-color: rgb(255, 159, 67);
  color: rgb(255, 159, 67);
}

.chatbot-question-form .input-select-label-primary--active {
  color: rgb(255, 159, 67);
}

.chatbot-question-form .vs-input-primary .vs-input--input:focus {
  border: 1px solid rgb(255, 159, 67) !important;
}

.chatbot-question-form .vs-input-primary.isFocus .vs-input--label {
  color: rgb(255, 159, 67);
}

.chatbot-question-form .focusx.vs-textarea-primary {
  border-color: rgb(255, 159, 67) !important;
}

.floatCounter {
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem;
  color: #777;
}
</style>
